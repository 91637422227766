/* My Styles */
/* Generic Styles*/

.container-landingpage {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  background-image: none;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top right;
  float: left;
}

.banner-landing {
  background: url('../../assets/images/landing-page/banner-bg.png') no-repeat;
  width: 100%;
  height: 6782px;
  background-position: right 260px;
}

.container-left-landing {
  max-width: 500px;
  width: 100%;
  float: left;
  padding-left: 5.8rem;
  text-align: left;
  padding-top: 50px;
}

.container-right-landingpage {
  max-width: 660px;
  width: 100%;
  float: left;
  padding-top: 100px;
}

.navbar {
  background: linear-gradient(180deg, #387c95 -130%, #002b3a 100%);
  padding: 15px;
  overflow: hidden;
  align-items: center;
}

.w-100 {
  width: 100%;
}

.logo {
  width: 177.551px;
  height: 42px;
  margin-left: 2.5rem;
  transform: scale(0.8);
}

h2.heading-text {
  font-family: 'Poppins', sans-serif;
  font-size: 45px;
  font-weight: 700;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: center;
  width: 1000px;
  margin: 0 auto;
  text-transform: uppercase;
}

/* landing page styles*/
.container-landing .banner-section {
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.banner-section .container-left-landing {
  padding-top: 150px;
}

.banner-section .container-right-land {
  padding-left: 25px;
  padding-right: 25px;
}

.banner-content h1 {
  color: #002b3a;
  font-family: 'Poppins', sans-serif;
  font-size: 45px;
  font-weight: 700;
  line-height: 55px;
  text-transform: uppercase;
}

.banner-btn {
  padding-top: 30px;
}

.banner-btn a {
  margin-right: 20px;
  text-decoration: none;
}

.primary-btn {
  background: #ff4200;
  padding: 10px 20px;
  color: #fff;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  text-decoration: none;
}

.banner-content p {
  color: #002b3a;
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
}

.budget-section h2.heading-text {
  color: #fff;
  padding-top: 300px;
  margin: 0 auto 40px;
}

.budget-cards {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto 150px;
}

.cards-info {
  flex-basis: 32%;
  box-sizing: border-box;
  background: #e8f9ff;
  padding: 20px 20px 40px;
}

.cards-inner {
  text-align: center;
}

.cards-inner img {
  width: 126px;
  height: 126px;
}

.cards-inner p {
  font-family: 'Montserrat', sans-serif;
  color: #002b3a;
  letter-spacing: 0em;
}

.cards-heading {
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
}

.cards-content {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 30px;
  min-height: 240px;
}

.branding-inner {
  background: #fff url('../../assets/images/landing-page/Subtract.png') no-repeat;
  background-position: top left;
  width: 1200px;
  margin: 0 auto;
  box-shadow: 0px 0px 100px 0px #0000001a;
  padding: 0 30px 30px;
}

.branding-inner h2 {
  padding: 6rem 0;
  color: #387c95;
  margin-bottom: 40px;
}

.branding-inner .text-heading {
  font-family: 'Montserrat', sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
  position: relative;
  padding-bottom: 10px;
}
.text-heading {
  position: relative;
  padding-bottom: 10px;
  display: inline-block;
}
.text-heading.center-text::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 5px solid #ff4200;
  width: 75%;
  transform: translate(15%, 0%);
}
.text-heading.left {
  text-align: left;
  display: table;
}
.text-heading.left::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 5px solid #ff4200;
  width: 75%;
  transform: translate(0%, 0%);
}

.branding-inner .text-para {
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  color: #171717;
  margin: 0 0 40px;
}

.branding-inner .text-para.center {
  text-align: center;
  width: 1000px;
  margin: 0 auto 40px;
}

.branding-inner .strong-heading1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: 0em;
  text-align: center;
}

.branding-inner .strong-heading2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 37px;
  letter-spacing: 0em;
  text-align: left;
}

.example-data {
  display: flex;
  justify-content: space-between;
  margin-bottom: 250px;
}

.icons {
  flex-basis: 32%;
  box-sizing: border-box;
  text-align: center;
}

.icons img {
  height: 100px;
}

.ex-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #387c95;
}

.branding-inner ul li {
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
}

.section-four {
  text-align: center;
  background: #002b3a url('../../assets/images/landing-page/got-ur-back.png') no-repeat;
  background-position: right -45px bottom 150px;
}

.section-four h1 {
  margin: 0;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 250px;
  padding-top: 50px;
  text-transform: uppercase;
}

.section-four p {
  color: #fff;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 45px;
  padding-bottom: 90px;
  padding-left: 60px;
  padding-right: 60px;
}

.section-five {
  width: 100%;
  height: 450px;
  flex-shrink: 0;
  /* background-color: #F0F0F0; */
  /* box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.1); */
}

.banner-five {
  background: #fff url('../../assets/images/landing-page/Subtract.png') no-repeat;
  background-position: top left;
  margin: 40px;
  box-shadow: 0px 0px 100px 0px #0000001a;
  text-align: center;
  padding: 50px;
}

.inner-banner {
  color: #387c95;
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  text-transform: uppercase;
}

.inner-info {
  margin-top: 50px;
  margin-left: 0;
  color: #171717;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
}

.left-div {
  width: 500px;
  /* background-color:#F0F0F0; */
}

.section-heading {
  color: #002b3a;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  text-transform: uppercase;
  margin-bottom: 40px;
}

.text-left {
  text-align: left !important;
  line-height: 28px;
  /* padding-left: 30px; */
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
}

.right-div {
  /* background-color:#F0F0F0; */
  margin-top: 20px;
  margin-left: 6rem;
}

.details {
  font-size: 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #002b3a;
  text-align: left;
}

.footer-section {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-around;
  width: 100%;
}
.footer-section .section-heading {
  text-align: left;
}

.input-field input[type='text'],
.input-field input[type='email'],
.input-field input[type='number'] {
  width: 100%;
  background-color: #fff;
  height: 35px;
  color: #000;
  margin-bottom: 15px;
  border: none;
  padding: 0 20px;
  margin-bottom: 0px;
}
.input-field {
  margin-bottom: 30px;
}

.submit-button {
  background-color: #ff4200;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 169px;
  height: 47px;
  text-transform: uppercase;
  border: none;
  display: block;
  cursor: pointer;
}

.form-fields {
  display: block;
}

.input-checkbox input[type='checkbox'] {
  transform: scale(0.8);
  width: 30px;
  height: 30px;
}

.label {
  margin-left: 10;
}

.checkbox-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
}

.section-footer {
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
}

.terms p {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 18px;
}

.color-change {
  color: #387c95;
}

.final-container {
  margin-top: 20px;
}

.error-color {
  color: red;
  display: inline-block;
  text-align: left;
  padding: 10px 0;
  width: 100%;
}

.input-checkbox .error-color {
  padding: 0;
}
