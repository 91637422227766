/* Styles */

/* MyISI Styles */
.right-container {
  .isi-docs {
    margin: 40px 0 60px;
    display: flex;
    flex-wrap: wrap;

    .isi-doc-upload {
      width: 40%;
      padding-right: 30px;

      #form-file-upload {
        height: 310px;
        width: 100%;
        max-width: 100%;
        text-align: center;
        position: relative;
        #label-file-upload {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          background-color: #00151d;
          .uploaded-file-name {
            font-family: 'Montserrat', sans-serif;

            font-weight: 400;
            font-size: 14px;
            color: #fff;
            padding: 0 5px 10px;
          }
          .isi-upload-dashed-border {
            border: 2px dashed #5d8d9d;
            width: 80%;
            height: 80%;
            border-radius: 20px;

            p {
              margin: 30px 0;
              font-family: 'Montserrat', sans-serif;

              font-size: 20px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: center;
            }
            img {
              display: block;
              margin: 0px auto 10px;
            }
            #input-file-upload {
              display: none;
            }
            .upload-button {
              cursor: pointer;
              border: none;
              font-family: 'Montserrat', sans-serif;
              background-color: #7896a0;
              color: #000;
              height: 59px;
              width: 200px;
              border-radius: 10px;
              font-size: 20px;
              font-weight: 400;
              line-height: 24px;
              text-align: center;
              margin: 0 auto;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover {
                background-color: #ff4200;
                color: #ffffff;
              }

              &:active {
                background-color: #ff4200;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    .isi-add-new-doc {
      width: 55%;
      .create-new-color {
        margin: 0 0 15px !important;
      }
      .isi-desc {
        font-family: 'Montserrat', sans-serif;

        font-size: 15px;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 30px;
      }
      .isi-inputs {
        input {
          color: #fff;
          padding: 15px 10px;
          box-sizing: border-box;
          margin-bottom: 30px;

          &:focus {
            border: none;
            box-shadow: none;
            outline: none;
          }
        }
        input::placeholder {
          color: #7b7b7b;
          width: 100%;
          box-sizing: border-box;
        }

        button {
          width: 244px;
          height: 59px;
          border-radius: 10px;
          // background: #fff;
          background-color: #7896a0;
          color: #000;
          border: none;
          font-family: 'Montserrat', sans-serif;
          font-size: 20px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          cursor: pointer;
          &:hover {
            background-color: #ff4200;
            color: #ffffff;
          }

          &:active {
            background-color: #ff4200;
            color: #ffffff;
          }
          &:disabled {
            background-color: #7896a0;
            color: #000;
            cursor: not-allowed;
          }
        }
      }
    }
  }
  .my-saved-isi-doc {
    .isi-saved-doc-info {
      padding: 30px 0;
      border-bottom: 1px solid #7896a0;
      display: flex;
      flex-wrap: wrap;
      &:last-child {
        border-bottom: none;
      }
      .isi-saved-doc-img {
        width: 30%;
      }

      .isi-saved-doc-data {
        width: 70%;
        padding-left: 30px;
        box-sizing: border-box;
        a {
          text-decoration: none;
        }
        .isi-info {
          font-family: 'Montserrat', sans-serif;

          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          color: #fff;
          padding: 0 0 10px 0;

          .isi-title {
            cursor: pointer;
            margin: 10px 0;
          }
        }

        .isi-update {
          font-family: 'Montserrat', sans-serif;

          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          color: #929292;
        }

        .isi-info {
          &.isi-border-bottom {
            border-bottom: 1px solid #5d8d9d;
            padding: 10px 0;
          }

          &:last-child {
            margin-top: 0px;
          }
          .isi-desc {
            font-size: 16px;
            font-weight: 400;
            font-family: 'Montserrat', sans-serif;
          }
        }
      }
    }
  }
}

/* ISI Details Page */

.isi-para {
  font-family: 'Montserrat', sans-serif;

  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  padding: 20px 0;

  &.isi-border-bottom {
    border-bottom: 1px solid #000000;
    padding: 15px 0;
  }
  .isi-title {
    cursor: pointer;
    margin: 10px 0;
  }
}

.isi-short-para {
  display: block;
  margin: 10px 0 0;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}
