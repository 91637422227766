.right-container {
  .input-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    input {
      margin: 20px 20px 0 0;
      width: 350px;
      height: 28px;
      color: #fff;
      padding: 0 10px;
      box-sizing: border-box;
      &::placeholder {
        margin-left: 4px;
        color: #7b7b7b !important;
        font-size: 15px;
      }
    }
  }

  .save-button {
    margin-top: 35px;

    button {
      width: 220px;
      height: 52px;
      color: #000;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      background-color: #7896a0;
      font-size: 20px;
      font-weight: 400;
      border-radius: 10px;
      border: none;
      margin-bottom: 40px;
      cursor: pointer;
      &:hover {
        background-color: #ff4200;
        color: #ffffff;
      }

      &:active {
        background-color: #ff4200;
        color: #ffffff;
      }
      &:disabled {
        background-color: #7896a0;
        color: #000;
        cursor: not-allowed;
      }
    }
  }
}
