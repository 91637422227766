.pdf-popup {
  position: fixed;
  width: 1000px;
  z-index: 99999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #d9d9d9b4;
  // overflow: auto;
  .pdf-wrapper {
    // position: relative;
    overflow: auto;
    height: 500px;
    button.close {
      width: 30px;
      height: 30px;
      border: none;
      font-weight: 500;
      font-size: 20px;
      position: absolute;
      top: -14px;
      right: -10px;
      z-index: 100000;
      cursor: pointer;
      border: 1.5px solid black;
      border-radius: 50px;
    }
    .page-buttons {
      button {
        position: absolute;
        bottom: 50%;
        width: 30px;
        height: 30px;
        font-size: 20px;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;

        cursor: pointer;
      }
      button:disabled {
        cursor: default;
      }
      .previous-btn {
        left: 0;
      }
      .next-btn {
        right: 9px;
        float: right;
      }
      .react-pdf__Page {
        overflow: auto;
        height: 500px;
      }
    }

    /* Add these styles in your .scss (Sass) file */
    .pdf-loader {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8); /* Semi-transparent background to overlay the PDF */
      position: absolute;
      top: 0;
      left: 0;
      &::before {
        content: '';
        display: block;
        width: 30px;
        height: 30px;
        border: 4px solid #0000009b; /* Border color of the circle */
        border-top: 4px solid transparent; /* Transparent top border to create a spinning effect */
        border-radius: 50%; /* Makes it a circle */
        animation: spin 1s linear infinite; /* Add a rotation animation */
      }
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.react-pdf__Page__canvas {
  width: 100% !important;
}

.react-pdf__Page__annotations {
  height: 0 !important;
}
.react-pdf__Page__textContent.textLayer {
  display: none !important;
}
