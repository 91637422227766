@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
.header-container {
  background: linear-gradient(180deg, #387c95 -130%, #002b3a 100%);
}
.layout-container {
  background: #2c3538;
  // height: 100vh;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}

.navBar {
  display: flex;
  // background-color: #000000;
  background: #00151d;
  padding: 15px;
}

.navbar {
  background: linear-gradient(180deg, #387c95 -130%, #002b3a 100%);
  padding: 15px;
  overflow: hidden;
  align-items: center;
}

.logo {
  width: 177.551px;
  height: 42px;
  margin-left: 2.5rem;
  transform: scale(0.8);
}

.navbar-logo {
  margin-left: 5px;
  width: 200px;
}

.right-button {
  margin-left: auto;
  .buttons {
    cursor: pointer;
    display: block;
    float: right;
    font-family: 'Montserrat', sans-serif;
    height: 48px;
    margin-right: 15px;
    width: 140px;
    background: rgb(255, 255, 255);
    border-radius: 10px;
    transition: all 0.5s ease 0s;
    border: none;
    background-color: #7896a0;
    color: #000;
    &:hover {
      background-color: #ff4200;
      color: #ffffff; // Change text color on hover if needed
    }

    &:active {
      background-color: #ff4200;
      color: #ffffff; // Change text color on click if needed
    }
  }
}

.menu {
  color: white;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  .menu-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    .menu-item {
      padding: 8px 20px;
      cursor: pointer;
      &.active {
        a {
          border-bottom: 4px solid #ff4200;
          font-weight: bold;
        }
      }
      a {
        color: white;
        text-decoration: none;
        padding-bottom: 5px;
        font-weight: normal;
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

/* Responisive for 992 screens  */
@media only screen and (max-width: 1169px) and (min-width: 992px) {
  .budget-section h2.heading-text,
  .banner-section .container-left-landing {
    padding-top: 100px;
  }
  .right-container .my-saved-isi-doc .isi-saved-doc-info .isi-saved-doc-data {
    padding-left: 60px;
  }
  .right-container .isi-docs .isi-doc-upload #form-file-upload #label-file-upload .isi-upload-dashed-border .upload-button {
    width: 150px;
  }
  .myfont_container .myfont .right-container-fonts {
    margin-right: 10%;
  }
  .container-div2 {
    width: 100%;
  }
  .container-right-landingpage {
    float: none;
  }
  .section-five {
    background-color: #f0f0f0;
    padding-bottom: 30px;
  }
  .banner-landing,
  .section-five {
    height: 100%;
  }
  .branding-inner,
  .budget-cards,
  h2.heading-text {
    width: 100%;
    box-sizing: border-box;
  }
  .budget-cards {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 1441px) {
  .container-div,
  .layout-container {
    max-width: 100% !important;
  }
  .section-four p {
    margin: 0;
  }
  .banner-five {
    margin: 0 40px;
    background-repeat: repeat-x;
  }
  .footer-section,
  .section-five {
    background: #f0f0f0;
  }
  .banner-landing {
    height: 6505px;
  }
}
@media only screen and (min-width: 1920px) {
  .banner-landing {
    height: 6422px;
  }
}
