.my-projects-container{
height: 100vh;
.container-myprojects {
  margin: auto;
  height: 440px;
  width: 1200px;
  max-width: 1654px;
  max-height: 734px;
  background-color: #00151d;
  overflow-y: scroll;

  .myprojects-section {
    margin: 50px 0;
    .message {
      color: #fff;
      margin: 12rem;
      padding-left: 19rem;
      font-size: 20px;
      font-family: 'Montserrat', sans-serif;
    }
    .myproj-cards {
      display: flex;
      flex-wrap: wrap;
      width: 90%;
      align-items: center;
      justify-content: space-evenly;
      border-bottom: 1px solid #000;
      margin: 0 auto;
      padding: 3rem 0;

      &:last-child {
        border-bottom: none;
        padding: 3rem 0 2rem;
      }

      .proj-img-card {
        width: 18%;
        padding-right: 2%;
        img {
          border: 1px solid #fff;
          width: 201px;
          height: 270px;
        }
      }
      .Versioning {
        font-family: 'Montserrat', sans-serif;
        color: #fff;
        left: 29%;
        font-size: 16px;

        .reveal {
          position: relative;
          cursor: pointer;
          transition: all 0.3s ease;
          margin-top: 7%;
          .rotate-img {
            position: absolute;
            top: 10%;
            margin-left: 7px;
            transition: transform 0.3s ease-in-out;
            &.rotated {
              transform: rotate(90deg);
              top: 2%;
            }
          }
        }
        .version-item {
          display: flex;
          justify-content: space-evenly;
          margin-top: 11px;
        }
        .versions {
          display: block;
          justify-content: space-evenly;
          margin-top: 11px;
          .proj-name-versions {
            margin-top: 5px;
            padding-right: 17px;
            // text-decoration: inherit !important;
          }
          .open-proj {
            padding-left: 20px;
            padding-right: 15px;
            .open-proj-button {
              height: 36px;
              width: 161px;
              background-color: #ff4200;
              color: #fff;
              font-family: 'Montserrat', sans-serif;
              border: none;
              border-radius: 5px;
              cursor: pointer;
            }
          }
          .create-version,
          .preview {
            position: relative;
            // padding-left: 17px;
            padding-right: 12px;
            margin-top: 5px;
            text-decoration: underline;
          }

          .preview-popup {
            position: absolute;
            top: -140px;
            left: -10px;

            img {
              height: 100%;
              width: 100%;
              border: 5pt solid white;
            }
          }

          .preview-text {
            margin: 0;
          }

          .preview,
          .delete {
            color: rgb(164, 164, 164);
          }

          .delete {
            margin-top: 5px;
            // padding-left: 5px;
            text-decoration: underline;
          }
        }
        .dotted-hr {
          border-bottom: 1px dotted #7896a0;
          margin-top: 15px;
        }
      }
    }

    .proj-card-info {
      text-align: left;
      width: 75%;
      padding-left: 5%;
      .project-title {
        font-family: 'Montserrat', sans-serif;
        font-size: 36px;
        font-weight: 400;
        line-height: 44px;
        letter-spacing: 0em;
        color: #fff;
        margin: 0 0 10px;
      }

      .updates {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        color: #fff;
        margin: 0 0 50px;
      }

      .proj-access {
        display: flex;
        flex-wrap: wrap;
        align-items: start;

        button {
          font-family: 'Montserrat', sans-serif;
          font-size: 20px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          color: #fff;
          border: none;
          cursor: pointer;
        }

        .launch-btn {
          width: 200px;
          height: 59px;
          border-radius: 10px;
          margin-right: 25px;
          background: #ff4200;
          font-size: 20px;
        }
        .dwn-btn {
          width: 259px;
          height: 59px;
          border-radius: 10px;
          margin-right: 25px;
          color: #000;
          // background: #fff url('../../assets/images/myprojects/dwn-icon.png') no-repeat;
          background-position: 10% center;
          background-color: #7896a0;
          font-size: 20px;
        }

        button.dup-proj-btn {
          background: transparent;
          font-family: 'Montserrat', sans-serif;
          width: 259px;
          height: 59px;
          border: none;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0em;
          text-align: left;
          color: #000;
          font-size: 20px;
          background-position: 10% center;
          background-color: #7896a0;
          padding-left: 56px;
          border-radius: 10px;
        }
      }
    }
  }
}
}

