.author-container {
  display: flex;
  flex-wrap: wrap;
  .container-heading {
    margin-top: 20px;
    font-weight: 800;
    font-family: 'Montserrat', sans-serif;

    font-size: 18px;
  }
  .document-grid {
    display: flex;
    flex-wrap: wrap;
    .saved-pdf-container {
      .saved-pdf-info {
        margin-top: 15px;

        .container-left-side {
          padding: 12px;
          width: 350px;
          height: 320px;
          margin: 10px 10px 10px 4px;
          border-radius: 20px;
          background-color: #00151d;
          h3 {
            margin-top: 15px;
            font-size: 18px;
            bottom: 0;
            font-family: 'Montserrat', sans-serif;

            color: #fff;
          }
          p {
            color: #fff;
            font-family: 'Montserrat', sans-serif;

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .anchor-tag {
          color: #c2c2c2;
          font-family: 'Montserrat', sans-serif;

          font-size: 16px;
          padding-bottom: 10px;
          cursor: pointer;
          transition: color 0.2s;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
}
