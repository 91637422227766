.template-wrapper {
  margin-left: 20px;
  .options {
    display: flex;
    .box {
      color: #fff;
      text-align: center;
      font-family: 'Montserrat', sans-serif;

      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 7px;
      border: 1.8px solid #000;
      background: #00151d;
      padding: 5px 15px;
      margin-top: 30px;
      margin-right: 8px;
      cursor: pointer;
      &:hover,
      &.selected {
        background-color: #ff4200;
        color: #fff;
      }
    }
  }
  .wrapper {
    display: flex;
    padding: 10px;
    color: #ffffff;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    color: #ffffff;
    .temp-btn {
      display: flex;
      justify-content: space-between;
      padding: 2px;
      .cat-type-name {
        &.background {
          background: #000;
          align-items: center;
          justify-content: space-between;
          height: 35px;
          width: max-content;
          border-radius: 100px;
          border: 2px solid #000;
        }
        p {
          color: #fff;
          font-family: 'Montserrat', sans-serif;

          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          text-align: center;
          margin: 0;
          line-height: 35px;
          display: inline-block;

          padding: 0 20px;
        }
        .close-image {
          float: right;
          margin-top: 12px;
          cursor: pointer;
          padding-right: 6px;
        }
      }
    }
    .filter-clear {
      cursor: pointer;
      width: 70%;
      padding: 10px;
    }
  }
  .filter-template-wrapper {
    position: relative;

    .filter-options {
      float: right;
      margin-right: 20px;
      border: 1.8px solid #000;
      border-radius: 7px;
      width: 23%;
      position: absolute;
      right: 0;
      .filter {
        position: relative;
        width: 240px;
        border-radius: 7px;
        padding: 5px 10px;
        font-size: 17px;
        font-family: 'Montserrat', sans-serif;

        background: #00151d;
        cursor: pointer;
        img {
          height: 30px;
          float: right;
          margin-top: -4px;
        }
      }
      .filter-list {
        display: block;
        background: #00151d;
        border-radius: 7px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 1;
        padding: 10px;
        width: 240px;
        height: 300px;

        .filter-category {
          margin-bottom: 10px;
          .filter-category-heading {
            color: #fff;
            font-family: 'Montserrat', sans-serif;

            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .filter-heading {
            font-weight: bold;
            margin-bottom: 5px;
          }
          .checkbox-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 10px;
            input {
              margin-right: 8px;
              border: 2px solid #fff;
              background: #00151d;
              -webkit-appearance: none;
              -moz-appearance: none;
              -o-appearance: none;
              appearance: none;
              width: 17px;
              height: 17px;
            }
            :checked {
              background: #fff url('../../../assets/images/Ellipse\ 34.png');
              display: inline-block;
              background-size: 9px;
              background-repeat: no-repeat;
              background-position: center center;
              width: 17px;
              height: 17px;
              clip-path: inset(0);
            }
          }
          .filter-checkbox {
            margin-bottom: 5px;

            label {
              display: block;
              font-family: 'Montserrat', sans-serif;

              font-size: 14px;
            }
          }
        }
      }
    }

    .template-container {
      width: 100%;
      .template-list {
        margin-left: 0;
        .selected-template-type-heading {
          p {
            color: #fff;
            font-family: 'Montserrat', sans-serif;

            font-size: 22px;
            font-weight: 400;
          }
        }

        .my-masonry-grid {
          display: flex;
          margin-left: -15px;
          width: auto;
        }

        .my-masonry-grid_column {
          padding-left: 15px;
          background-clip: padding-box;
        }

        .template-item {
          cursor: pointer;
          margin-top: 55px;
          margin-bottom: 15px;
          height: 220px;
          // width: 140px;
          border-radius: 20px;
          background: #00151d;
          padding: 22px 38px;
          .template-image {
            object-fit: contain;
            width: 100%;
            height: 200px;
            object-position: center center;
          }
        }
      }
    }
  }
}
