.qr-code-generator-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 0px;
}

.color-picker-container {
  background-color: #19292e;

  div {
    margin-right: 50px;
    color: white;
  }
}

.text-input-container {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  border: none;

  // margin-bottom: 20px;

  input {
    &::placeholder {
      color: #a8a8a8;
      font-size: 12;
    }
    color: #fff !important;
    background: #00151d;
    border: none;
    padding: 10px 12px;
    margin-top: -0.9rem;
    width: 300px !important;
  }

  h4 {
    margin-bottom: -0.6rem;
  }
}
.option-wrapper {
  position: relative;
  float: right;
  background: #00151d;
  padding-top: 9px;
  padding-left: 20px;
  padding-bottom: 9px;
  margin-top: -13.5rem;
  margin-right: 20px;
  .options {
    color: #fff !important;
    border: none;
    width: 290px;

    img {
      float: left;
      height: 17px;
      margin-right: 12px;
      margin-top: 3px;
    }
    .closed-styles {
      float: right;
      margin-right: -25px;
      height: 15px;
    }
    .open-styles {
      float: right;
      margin-right: -43px;
      height: 10px;
    }
    .arrow {
      height: 17px;
    }
  }

  .options-container {
    display: flex;
    flex-direction: column;
    padding-top: 4px;
    padding-bottom: 4px;
    border: none;
    background: #00151d;
    // margin-bottom: 20px;

    input {
      width: 280px;
      &::placeholder {
        color: #19292e;
        font-size: 12;
      }

      color: #fff !important;
      background: #19292e !important;
      border: none;
      padding: 10px 12px;
      margin-top: -0.9rem;
    }

    h4 {
      margin-bottom: -0.6rem;
    }
  }
}

.qr-code-preview {
  align-items: center;
  margin-top: 15px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  canvas {
    border: 2px solid #ccc; /* Optional border for the QR code preview */
    border-radius: 5px;
    margin-top: 10px;
    margin-left: 9px;
  }
  .save-button {
    display: flex;
    flex-direction: column;
    margin-top: -50px;
    margin-left: -30px;
    .button-generate {
      background-color: #7896a0;
      border: none;
      border-radius: 5px;
      color: #fff;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      height: 35px;
      width: 130px;
      margin-top: 5px;
      margin-bottom: 1px;
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;
      &:hover {
        background-color: #ff4200;
        color: #ffffff; // Change text color on hover if needed
      }

      &:active {
        background-color: #ff4200;
        color: #ffffff; // Change text color on click if needed
      }
    }
  }
}

.save-generate {
  background-color: #7896a0;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  height: 35px;
  width: 130px;
  margin-top: 40px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    background-color: #ff4200;
    color: #ffffff; // Change text color on hover if needed
  }

  &:active {
    background-color: #ff4200;
    color: #ffffff; // Change text color on click if needed
  }
}
/* Add these styles for the modal */
.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.popup-content {
  background-color: #19292e;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 100%;
  position: relative;
}

.close-popup {
  color: #fff;
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 20px;
  cursor: pointer;
}

.message-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4caf50;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}
#background {
  input {
    &::placeholder {
      color: #fff;
    }
    color: #fff !important;
    background: #00151d;
    border: none;
    padding: 10px 12px;
    margin-top: -0.7rem;
  }
}
.no-qr-container {
  /* Optional border for the QR code preview */
  border-radius: 5px;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 9px;
  background: #00151d;

  width: 128px;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #00151d; /* Optional: Add a border for better visibility */
}
