.upload-file {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .upload-button-reference {
    margin-top: 10px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    width: 220px;
    height: 40px;
    border-radius: 10px;
    background-color: #7896a0;
    justify-content: center;
    border: none;
    color: #000;
    cursor: pointer;
    &:hover {
      background-color: #ff4200;
      color: #ffffff;
    }

    &:active {
      background-color: #ff4200;
      color: #ffffff;
    }
    input[type='file'] {
      display: none;
    }

    span {
      font-family: 'Montserrat', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 50px;
      cursor: pointer;
    }

    svg {
      margin: 9px 11px -6px 2px;
    }
  }
  p {
    font-family: 'Montserrat', sans-serif;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}
