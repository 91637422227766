.add-logo-containers {
  width: 100%;
  padding: 0 30px 60px; /* Center the container horizontally */
  text-align: center; /* Center the text within the container */
  font-size: 4px; /* Decrease the font size */

  .add-logo {
    float: left;
    cursor: pointer;
    margin-top: 60px;
    width: 175px;
  }
  .add-logo img {
    display: block;
    margin: 0 auto 20px;
    text-align: center;
    height: 40px;
  }
  .add-file {
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
  }
}
