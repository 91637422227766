.layout-left-container {
  background: #19292e;
  border-right: 1px solid #5d8d9d;

  .box {
    width: 373px;
    height: auto;
    display: flex;
    border: 0;
    border-bottom: 1px solid #5d8d9d;
    cursor: pointer;

    span {
      font-family: 'Montserrat', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 33px;
      color: #fff;
      padding: 34px 0 24px 35px;
    }

    &.active {
      background: #ff4200;
    }

    &:nth-child(9) {
      border-bottom: 0;
    }
  }
}
