/* * {
  overflow: hidden;
} */
.container{
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  background-color:#FFF;
  background-image: url('../../assets/images/graph_bg.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top right;
  float: left;
}
.container-left{
  max-width: 660px;
  width: 100%;
  float: left;
  padding-left: 5.8rem; 
  text-align: left;
  padding-top: 50px;
}
.container-right{
  max-width: 500px;
  width: 100%;
  float: left;
  padding-top: 50px;
}
.navbar {
  background: linear-gradient(180deg, #387c95 -130%, #002b3a 100%);
  padding: 15px;
  overflow: hidden;
  align-items: center;
}
.logo {
  width: 177.551px;
  height: 42px;
  margin-left: 2.5rem;
  transform: scale(0.8);
}
.welcome {
  color: #387c95;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-style: normal;
  text-transform: uppercase;
  margin: 0;
}
.first-line {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 45px;
  text-transform: capitalize;
  padding-top: 20px;
  padding-bottom: 30px;
}
.second-line {
  margin-left: -4rem;
}
.para {
  color: #000;
  max-width: 660px;
  right: 0;
  margin-top: 1rem;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 138.455%;
  text-align: left;
}
.user-form {
  padding-left: 150px;
}
.heading2 {
  margin-bottom: 0.5rem;
  color: #000;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}

.input-box input[type="text"], .input-box input[type="password"], .input-box input[type="email"]{
  margin-top: 20px;
  padding: 6px;
  width: 280px;
  border: none;
  flex-shrink: 0;
  background: #eaf4ff;
  float: left;
  padding: 10px;
}

input::placeholder {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.register-button {
  margin-top: 20px;
  padding: 8px;
  width: 300px;
  max-width: 500px;
  border: none;
  background-color: #ff4200;
  color: white;
  font-weight: bold;
  font-size: 12px;
  float: left;
  text-transform: uppercase;
  cursor: pointer;
}
.container3 {
  padding-top: 150px;
  padding-bottom: 3rem;
  float: left;
  width: 100%;
}
.terms {
  color: #002b3a;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
}
.subtitle-text{
  margin-left: 2.8rem;
  font-family: "Montserrat", sans-serif;
  color: #000;
  font-size: 35px;
  max-width: 600px;
  text-align:left;
  font-weight: 400;
}
.user-form.success{
  margin-top: -10rem;
}
.login-button{
  margin-top: 30px;
  padding: 10px;
  width: 134px;
  max-width: 500px;
  border: none;
  background-color: #ff4200;
  color: white;
  font-weight: bold;
  font-size: 12px;
  float: left;
  cursor: pointer;
}
.remember_box{
  float: left;
  width: 100%;
  text-align: left;
  margin-top: 30px;
}
.remember_box label{
  padding-left: 10px;
}

.login-page-link{
  float: left;
  width: 100%;
  text-align: left;
  margin-top: 30px;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  cursor: pointer;
  text-decoration: underline;
}
.login-page-link span{
  padding-right: 40px;
}

.script {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 20px;
}

/* Hide the default checkbox */
.remember_box input[type=checkbox] {
  visibility: hidden;
}

/* checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.checkmark-remember:after {
  content: "";
  position: absolute;
  display: none;
}

/* display checkmark when checked */
.script input:checked ~ .checkmark-remember:after {
  display: block;
}

/* Hide the default checkbox */
.remember_box input[type=checkbox] {
  visibility: hidden;
}

/* Creating a custom checkbox
based on demand */
.checkmark-remember {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: #eaf4ff;
  margin-left: -25px;
}



/* Specify the background color to be
shown when checkbox is checked */
.main input:checked ~ .checkmark-remember {
  background-color: #eaf4ff;
}



/* Display checkmark when checked */
.main input:checked ~ .checkmark-remember:after {
  display: block;
}

/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and 
showing only two border to make it look
like a tickmark */
.main .checkmark-remember:after {
  left: 8px;
  bottom: 5px;
  width: 3px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* tempNameEmail css  */
.temp-wrapper{
  display: flex;
  margin-top: 15px;
  margin-right: 55px;
  justify-content: center;
  align-items: center;
}
