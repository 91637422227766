.myfont_container {
  .heading {
    margin-top: 0;
    color: #fff;
    font-size: 13px;
    font-style: normal;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
  }
  .myfont-header {
    padding: 10px 0;
    input {
      max-width: 470px;
      padding: 6px 10px;
      width: 270px;
    }
    input::placeholder {
      max-width: 470px;
      padding: 6px 0px;
      width: 100%;
    }
  }

  .myfont {
    display: flex;
    justify-content: center;
    align-items: center;

    .right-container-fonts {
      padding: 10px 0;
      margin-top: 0;
      width: 100%;
      .font-container {
        margin-top: -3px;
        padding: 4px 4px 0 4px !important;
        width: 290px;
        flex-shrink: 0;
        background-color: #00151d !important;
        color: #fff;
        font-size: 13px;
        font-family: 'Nunito', sans-serif;
        font-weight: 800;
        border: none;
      }
      .css-13cymwt-control,
      .css-t3ipsp-control,
      .css-b62m3t-container {
        width: 290px;
        background-color: #00151d !important;
        border: none;
        color: #0000;
        cursor: pointer;
       
        svg {
          color: #000;
        }
      }
       
      .css-1dimb5e-singleValue {
        color: #ffffff;
      }
      .css-1nmdiq5-menu {
        background-color: #00151d !important;
        color: #ffff;
      }
      .css-1u9des2-indicatorSeparator {
        display: none;
      }
      .css-1f43avz-a11yText-A11yText {
        color: #000;
      }
    }
    .additional-div-font {
      background-color: #fff;
      color: #000;
      height: 100%;
      width: 100%;
      padding: 10px;
    }

    div[id^='font-picker'] {
      .dropdown-button,
      .font-list-item {
        margin-top: -3px;
        padding: 4px 4px 0 4px !important;
        width: 290px;
        flex-shrink: 0;
        background-color: #00151d !important;
        color: #fff;
        font-size: 13px;
        font-family: 'Nunito', sans-serif;
        font-weight: 800;
        border: none;
      }
      ul.font-list {
        width: 290px;
        li button {
          &.active-font,
          &:hover {
            background: blue;
          }
        }
      }
    }
  }
  .font-item {
    .update-button {
      background: #000;
      border: none;
      border-radius: 5px;
      color: #fff;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      height: 35px;
      width: 170px;
      margin-right: 7px;
      cursor: pointer;
    }

    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .modal-content {
      background: #19292e;
      padding: 20px;
      border-radius: 8px;
      text-align: center;
      color: #fff;
      p {
        font-family: 'Montserrat', sans-serif;
      }
    }

    .delete-button,
    .cancel-button {
      margin-top: 10px;
      padding: 8px 16px;
      cursor: pointer;
      background-color: #7896a0;
      color: #000;
      font-family: 'Montserrat', sans-serif;
      border: none;
      border-radius: 4px;
      margin-right: 10px;
      &:hover:enabled {
        background-color: #ff4200;
        color: #ffffff;
      }

      &:active:enabled {
        background-color: #ff4200;
        color: #ffffff;
      }
    }
  }
  input.font-adjust {
    margin-top: -3px;
    padding: 4px 4px 0 4px !important;
    width: 290px !important;
    flex-shrink: 0;
    background-color: #00151d !important;
    color: #fff;
    font-size: 13px;
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
    border: none;
    height: 28px;
    input.font-adjust::placeholder {
      background: #00151d;
      border: none;
      color: #fff;
      max-width: 470px;
      padding: 6px 0px;
    }
  }
  input.font-adjust:focus-visible {
    outline: none;
  }

  .save_div_row.font {
    button {
      margin-bottom: 40px;
      margin-right: 10px;
    }
  }
  .adjust-font {
    margin: 5px 0 10px;
    span,
    u,
    s {
      margin: 0 5px;
      cursor: pointer;
    }
  }
  .saved-fonts {
    p.line2 {
      height: auto;
      margin: 30px 0 10px;
      font-size: 18px;
      font-weight: 700;
      font-size: 18px;
      text-transform: uppercase;
    }
  }
}
