.container-div {
  background: #2c3538;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
 
  padding-bottom: 76px;
  ::-webkit-scrollbar {
    background: #2c3538; /* Customize scrollbar background for .right-container */
 
  }


  //dashboard
  .center {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 5rem;
  }

  .square {
    width: 170px;
    height: 170px;
    padding: 10px;
    border-radius: 30px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 130px;
    cursor: pointer;
  }

  .plus_icon {
    display: block;
    width: 125px;
    height: 125px;
    flex-shrink: 0;
  }

  .text {
    margin-top: auto;
    padding-top: 14px;
    color: white;
    font-family: 'Montserrat', sans-serif;
  }

  .hover-block {
    background: #00151d;
    color: #fff;
    max-width: 400px;
    width: 100%;
    padding: 50px;
    font-size: 33px;
    text-align: left;
    font-family: 'Montserrat', sans-serif;

    position: absolute;
    top: 160px;

    span {
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
@media only screen and (min-width: 1440px){
  .container-div{
    &.dash{
      height: 100vh;
    }
  }
}

@media only screen and (min-width: 1792px){
  .container-div{
 height: 100vh;
  }
}

