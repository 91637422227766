.claim-container {
  .claim-heading {
    margin: 20px 10px;
    color: #fff;
    font-family: 'Montserrat', sans-serif;

    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .claim-container-one {
    width: 1000;
    // height: 200px;
    background-color: #00151d;
    border-radius: 18px;
    padding: 8px 15px;

    h3 {
      padding: 8px 15px;
      // margin-left: 15px;
      height: 4px;
    }
    .claim-box {
      display: flex;
      justify-content: space-between;
      border-bottom: 1.5px solid #5d8d9d;
      // margin: 40px 0 15px 7px;
      padding: 15px 0px;
      margin: 0 20px;

      &:last-child {
        border-bottom: none;
        padding-bottom: 30px;
      }
      p {
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
      }
      button {
        width: 176px;
        height: 38px;
        border-radius: 10px;
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        background-color: #7896a0;

        border: none;
        cursor: pointer;
        &:hover {
          background-color: #ff4200;
          color: #ffffff;
        }

        &:active {
          background-color: #ff4200;
          color: #ffffff;
        }
      }
    }
  }
}
