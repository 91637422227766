.loader {
    border: 4px solid rgba(0, 0, 0, 0.2);
    border-top: 4px solid #000; // Black border for the loader
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite; // Create a spinning animation
    margin: 0 auto; // Center the loader horizontally
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }