

.custom-dropdown {
  position: relative;
  width: 290px;

 &__trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 38px;
    padding: 8px 12px;
    background: #00151d;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 18px;

     
  }

  &__selected {
    flex-grow: 1;
    text-align: left;
    margin-right: 10px;
  }

  &__arrow {
    font-size: 14px;
    color: #000;
    transition: transform 0.2s ease;

    &.open {
      transform: rotate(180deg);
    }
  }

  &__content {
    position: absolute;
    left: 0;
    width: 100%;
    height: 200px;
    background: #00151d;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    overflow-y: auto;
    
  }

&__option {
    padding: 10px 15px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: #f8f9fa;
      color: #000; 
    }

    &.selected {
      background: #edf2f7;
      color: #000; 
    }
  }

  &__loading {
    padding: 10px 15px;
    text-align: center;
    color: #718096;
    font-size: 14px;
  }
}

.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 8px;

  span {
    width: 8px;
    height: 8px;
    background-color: #718096;
    border-radius: 50%;
    display: inline-block;
    animation: bounce 0.6s infinite;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
}
