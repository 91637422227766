.right-container.reference {
  .add-reference {
    margin-top: 20px;
    width: 342px;
    height: 30px;
    flex-shrink: 0;
    color: #fff;
    font-family: 'Montserrat', sans-serif;

    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .create-new-color {
    margin-top: 0;
  }
  .saved-ref-title {
    font-family: 'Montserrat', sans-serif;

    font-weight: 400;
    font-size: 20px;
    margin-top: 10px;
  }
  .saved-reference {
    display: flex;
    margin-top: 28px;

    .dropdown {
      position: relative;
      height: 28px;
      width: 210px;
      background: #00151d;

      .dropdown-button {
        width: 210px;
        height: 38px;
        font-family: 'Nunito', sans-serif;
        font-size: 15px;
        cursor: pointer;
        font-size: 17px;
        font-weight: 400;
        line-height: normal;
        background: #00151d;
        border: none;
        padding: 5px 10px;
        box-sizing: border-box;
        position: relative;

        img {
          height: 30px;
          float: right;
          margin-top: -2px;
        }

        .dropdown-content {
          width: 210px;
          font-family: 'Nunito', sans-serif;
          font-size: 15px;
          box-sizing: content-box;
          cursor: pointer;
          background: #00151d;
          position: absolute;
          left: 0;

          .dropdown-item {
            padding: 10px;
            color: #fff;
            font-family: 'Nunito', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: normal;
          }
          .middle-line {
            width: 190px;
            margin: 0 auto;
            background-color: #5d8d9d;
            height: 1px;
          }
        }
      }
    }
    .search-bar {
      position: relative;
      .search-image {
        height: 25px;
        position: absolute;
        right: 10px;
        bottom: 8px;
        cursor: pointer;
      }
      input {
        margin-left: 10px;
        width: 550px;
        height: 38px;
        background-color: #00151d;
        border: none;
        padding: 5px 10px;
        box-sizing: border-box;

        &::placeholder {
          color: #7b7b7b;
          font-family: 'Montserrat', sans-serif;

          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
