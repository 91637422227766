.container-div2 {
  margin: auto;
  display: flex;
  justify-content: center;
  height: auto;
  width: 1200px;
  background: #19292e;
  .right-container {
    border: 0;
    color: #fff;
    text-align: left;
    padding: 20px;
    width: 100%;
    height: 785px;
    overflow-y: scroll;
    overflow-x: hidden;

    .right-section-title {
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-size: 30px;
      margin: 0;
    }
    input {
      background: #00151d;
      border: none;
      color: #fff;
      width: 100%;
    }
    input::placeholder {
      background: #00151d;
      border: none;
      color: #fff;
      width: 100%;
    }
    input:focus-visible {
      outline: none;
    }
    .create-new-color {
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-size: 20px;
      margin-top: 30px;
    }
    .save_div_row {
      margin-top: 20px;
      button {
        background-color: #7896a0;
        border: none;
        border-radius: 5px;
        color: #000;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        height: 35px;
        width: 170px;
        cursor: pointer;
        &:hover {
          background-color: #ff4200;
          color: #ffffff;
        }

        &:active {
          background-color: #ff4200;
          color: #ffffff;
        }
      }
    }
    .image-container-list {
      .qr-containers {
        position: relative;

        float: left;
        text-align: center;
        margin-top: 30px;
      }
      .qr-wrapper {
        width: 185px;
        height: 110px;
      }
      .image-containers {
        position: relative;
        width: 175px;
        float: left;
        margin-left: 18px;
        margin-right: 18px;
        padding: 18px 25px 18px 15px;
        text-align: center;
        margin-top: 30px;
        &:nth-child(3n + 1) {
          clear: left;
        }

        .image-wrapper {
          background-color: #d9d9d9;
          width: 185px;
          height: 110px;
          .images {
            padding-top: 5px;
            width: 150px;
            height: 100px;
            object-fit: contain;
          }
        }
        .image_name {
          margin: 10px 0;
          font-size: 14px;
          font-family: 'Montserrat', sans-serif;
        }
        .small-container {
          position: absolute;
          top: 0;
          right: 0;
          width: 75px;
          height: 30px;
          background-color: rgba(255, 255, 255, 0.7);
          color: white;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px;
          border-radius: 10px;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
          img {
            width: 20px;
            height: 20px;
            margin: 0;
            padding: 3px;
          }
        }
        &:hover {
          background-color: #00151d;
          border-radius: 15px;
        }
        &.active {
          .small-container {
            display: block;
          }
        }
      }
    }
  }
}
