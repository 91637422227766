.upload-button-pi {
  margin-top: 20px;
  margin-right: 18px;
  display: flex;
  align-items: center;
  width: 180px;
  height: 40px;
  border-radius: 10px;
  background-color: #7896a0;
  color: #000;
  justify-content: center;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #ff4200;
    color: #ffffff;
  }
  &:active {
    background-color: #ff4200;
    color: #ffffff;
  }

  input[type='file'] {
    display: none;
  }

  span {
    font-family: 'Montserrat', sans-serif;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
  }

  svg {
    margin: 9px 5px -6px 0;
  }
}
