.palettes-name-container {
  margin-left: 40px;
  margin-top: 30px;

  input {
    max-width: 470px;
    padding: 6px 10px;
  }
  input::placeholder {
    max-width: 470px;
    padding: 6px 0px;
  }
  .palettes-container-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 40px;
  }
  .color-container-lib {
    align-items: flex-end;
    display: flex;
    margin-top: 15px;
    margin-bottom: 20px;
    width: 100%;
    .color-container-box {
      position: relative;
    }
    .color-container-item {
      display: flex;
      flex-direction: column;
      margin-right: 35px;
      max-width: 115px;
      width: 100%;
    }
    .color-category-name {
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 15px;
      text-align: center;
    }
    .color-box {
      border-radius: 100px;
      height: 85px;
      margin: 0 auto;
      width: 85px;
    }
    .color-code-lib {
      margin-top: 15px;
      input {
        background: #00151d;
        border: none;
        color: #fff;
        max-width: 470px;
        padding: 6px 10px;
        width: 100%;

        input::placeholder {
          background: #00151d;
          border: none;
          color: #fff;
          max-width: 470px;
          padding: 6px 0px;
          width: 100%;
        }

        input:focus-visible {
          outline: none;
        }
      }
    }
    .add-color {
      bottom: 25px;
      cursor: pointer;
      position: relative;
      text-align: center;
      min-width: 145px;
      .add-file {
        text-align: center;
        margin-top: 10px;
        font-size: 18px;
        font-family: 'Montserrat', sans-serif;
      }
    }
  }

  .update-button {
    background-color: #7896a0;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    height: 35px;
    width: 170px;
    margin-right: 7px;
    cursor: pointer;
    &:hover {
      background-color: #ff4200;
      color: #ffffff; // Change text color on hover if needed
    }

    &:active {
      background-color: #ff4200;
      color: #ffffff; // Change text color on click if needed
    }
  }
}
.left-container {
  .palettes {
    height: 400px;
    overflow-y: scroll;
    scrollbar-color: green blue;
  }
}

.react-colorful {
  position: absolute !important;
  top: calc(100% - 325px);
  left: 0;
  z-index: 999999;
}
.color-box {
  border-radius: 100px;
  height: 85px;
  margin: 0 auto;
  width: 85px;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  background: #19292e;
}
::-webkit-scrollbar-thumb {
  background: #000;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}
::-webkit-scrollbar-corner {
  background: #484848;
}
::-webkit-scrollbar-track {
  background: #19292e;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: #19292e;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  color: #fff;
  p {
    font-family: 'Montserrat', sans-serif;
  }
}

.delete-button,
.cancel-button {
  margin-top: 10px;
  padding: 8px 16px;
  cursor: pointer;
  background-color: #7896a0;
  color: #000;
  border: none;
  font-family: 'Montserrat', sans-serif;
  border-radius: 4px;
  margin-right: 10px;
  &:hover {
    background-color: #ff4200;
    color: #ffffff;
  }

  &:active {
    background-color: #ff4200;
    color: #ffffff;
  }
}
.saved-color-palette {
  .color-container-item {
    margin-right: 65px !important;
  }
  .color-code-lib input {
    padding: 6px 10px !important;
  }
}
/* MySavedColorPalettes.css */

/* color palett end */
